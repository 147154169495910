import React from 'react'

const MenuWebbinars = ({
    section,
    setSection,
}) => {
    const liMenu = ['Generales', 'Organización']
  return (
    <div className="col-12 mb-3 ">
        <div className='card border-0 shadow rounded-10-px py-3 generic__show-main-container container-1'>
            <ul className="nav justify-content-center">
                {
                    liMenu.map((data, index) => {
                        return (
                            <li className={section === index ?
                                "nav-item border-bottom-light-blue-guay" :
                                "nav-item"}
                                key={index}>
                                <span
                                    className='nav-link-guay pointer'
                                    onClick={() => {
                                        setSection(index)
                                    }}
                                >{data}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>
  )
}

export default MenuWebbinars
