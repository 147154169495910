import React, { useEffect, useState } from 'react'
import { getWebinarsCountViewsLastMonth, getEnterpriseWithWebinarViews, getEnterpriseWebinarPercentage } from '../../../models/webinars_model'
import { last15Days, colorsArray, getMonthDays } from '../../plans/helpers/helpers'
import Chart from 'react-apexcharts';
import * as toastr from 'toastr';
import Datepicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import Loader from '../../genericComponents/Loader';


const EnterpriseInfo = () => {
    const [enterpriseSelected, setEnterpriseSelected] = useState(0);
    const [enterprises, setEnterprises] = useState([])
    const [videosPercent, setVideosPercent] = useState({
        series: [],
        options: {
            chart: {
                type: "bar",
                stacked: true, // Habilita barras apiladas     
                toolbar: {
                    show: true
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false, // Barras verticales
                    columnWidth: "50%", // Ancho de las columnas
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            dataLabels: {
                enabled: true, // Desactiva etiquetas para mayor claridad
            },
            xaxis: {
                categories: [], // Nombres de los videos
                title: {
                    text: "Webinars",
                },
                labels: {
                    trim: true, // Habilita el recorte automático
                    style: {
                        fontSize: '12px',
                    },
                    rotate: -70,
                    formatter: function (value) {
                        return value.length > 15 ? value.substring(0, 15) + "..." : value; // Trunca después de 15 caracteres
                    },
                },
            },
            yaxis: {
                title: {
                    text: "Número de Reproducciones",
                    offsetX: 10, // Ajuste para evitar que las etiquetas tapen el título
                },
                min: 0,
                max: 1, // Límite máximo de 100%
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0); // Asegura que solo se muestren enteros
                    },
                    offsetX: 0, // Asegura que las etiquetas no desplacen el título

                },
            },
            legend: {
                position: "top", // Coloca la leyenda en la parte superior
                verticalAlign: "middle",
                show: true,
                itemMargin: {
                    horizontal: 15,
                    vertical: 10
                }
            },
            title: {
                text: "% de Progreso en las Rreproducciones de los Webinars",
                align: "center",
                style: {
                    fontSize: "15px",
                },
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val} reproducciones`, // Tooltip de vistas
                },
                x: {
                    formatter: function (value) {
                        return value; // Muestra el nombre completo al hacer hover
                    },
                },
            },
        }
    });
    const [dailyMonthViews, setDailyMonthViews] = useState({
        series: [],
        options: {
            chart: {
                type: "bar",
                // height: 50,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%", // Ajusta el ancho de las barras
                    dataLabels: {
                        position: "top", // Posición de las etiquetas
                    },
                },
            },
            dataLabels: {
                enabled: false,
                formatter: (val) => `${val} reproducciones`, // Formatea las etiquetas
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"],
                },
            },
            stroke: {
                show: true,
                width: 3,
                colors: ["transparent"],
            },
            xaxis: {
                categories: getMonthDays(), // Fechas en X
                title: {
                    text: "Fechas",
                },
            },
            yaxis: {
                title: {
                    text: "Número de reproducciones",
                },
                tickAmount: 5,
            },
            title: {
                text: "Reproducciones Diarias de Webinars",
                align: "center",
                style: {
                    fontSize: "15px",
                },
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val} reproducciones`, // Tooltip de vistas
                },
            },
            colors: colorsArray,
            legend: {
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                itemMargin: {
                    horizontal: 15,
                    vertical: 10,
                },
                
            },
        }
    })
    const [dailyStackedMonthViews, setDailyStackedMonthViews] = useState({
        series:[],
        options: {
            chart: {
                type: "bar",
                stacked: true,
                toolbar: {
                    show: true
                },
            },
            
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 1,
                colors: [
                    "#fff"
                ]
            },
            xaxis: {
                categories: [],
                title: {
                    text: "Fechas"
                }
            },
            yaxis: {
                title: {
                    text: "Número de reproducciones",
                    offsetX: 10, // Ajuste para evitar que las etiquetas tapen el título
                },
                labels: {
                    formatter: function (value) {
                      return value.toFixed(0); // Asegura que solo se muestren enteros
                    },
                    offsetX: 0, // Asegura que las etiquetas no desplacen el título
                },
                // min: 0 // Asegura que el eje Y comience desde 0
                // tickAmount:11
            },
            title: {
                text: "Reproducciones Diarias de Webinars",
                align: "center",
                style: {
                    "fontSize": "15px"
                }
            },
            colors: colorsArray,
            legend: {
                position: "bottom",
                horizontalAlign: "center"
            },
            tooltip: {
                y: {}
            }
        }
    })
    const [dailyMonthShow, setDailyMonthShow] = useState(false);
    const [dailyStackedMonthShow, setDailyStackedMonthShow] = useState(false);
    const [progressShow, setProgressShow] = useState(false);
    const [showData, setShowData] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());


    useEffect(() => {
        handleGetEnterprises(selectedDate);
    }, [])
    

    // Función para procesar datos
    /* const processChartData = (data) => {
        // obtenemos los tiempos -> venrian siendo nuestros %
        const progressCategories = data.map(cat => cat.time);
        const uniqueProgressCategories = [...new Set(progressCategories)]; // Categorías de progreso
        const groupedData = {}; //objeto que usaremos para agrupar la informacion de los webinars
        
        // Agrupar por idWebinar
        data.forEach((item) => {
            // Verificar si ya existe el idWebinar en groupedData
            if (!groupedData[item.idWebinar]) {
                groupedData[item.idWebinar] = { 
                    title: item.title, // Guardar el título del video
                    progress: {} // Inicializar el objeto de progreso
                };
            }
            // Actualizar las vistas para el nivel de progreso correspondiente
            groupedData[item.idWebinar].progress[item.time] = (groupedData[item.idWebinar].progress[item.time] || 0) + item.views;
        });

        // Extraer títulos y series
        const titles = Object.values(groupedData).map((entry) => entry.title); // Títulos de los videos
        const series = uniqueProgressCategories.map((time) => ({
            name: `${time}%`,
            data: Object.values(groupedData).map((entry) => entry.progress[time] || 0),
        }));
    
        return { titles, series };
    }; */
    
    const handleGetEnterprises = date => {   
        setShowData(false);     
        setSelectedDate(date);
        const newDate = new Date(date);
        // Obtenemos el mes (de 0 a 11, se suma 1 para mostrar el mes correctamente)
        const month = newDate.getMonth() + 1;
        // Obtener el año
        const year = newDate.getFullYear();

        getEnterpriseWithWebinarViews(month,year).then(response => {
            if (response.validation) {
                if(response.data.enterpriseList.length > 0){
                    setEnterprises(response.data.enterpriseList)
                }else{
                    setEnterprises([])
                    setEnterpriseSelected(0);
                }
                
            }else{
                setEnterprises([]);
                setEnterpriseSelected(0);

            }
        })
        
    }

    const handleGetData = e => {
        e.preventDefault();
        // setEnterpriseSelected(idEnterprise)
        if(enterpriseSelected > 0){
            const newDate = new Date(selectedDate);
            // Obtenemos el mes (de 0 a 11, se suma 1 para mostrar el mes correctamente)
            const month = newDate.getMonth() + 1;
            // Obtener el año
            const year = newDate.getFullYear();

            getWebinarsCountViewsLastMonth(enterpriseSelected,month,year).then(response => {
                if (response.validation) {
                    // console.log(response.data)
                    if(response.data.dataWebinars.length > 0){
                        // Funcion que regresa los ultimos 15 dias (contando el día de hoy)
                        const arrMonthDays = getMonthDays(year,month);
                        // console.log('arrMonthDays: ',arrMonthDays);
                        
                        // Obtenemos los webinars que si tuvieron reproducciones
                        const webinarsWithDates = response.data.dataWebinars.filter(webinar => webinar.dates.length > 0);
                        // Creamos un arrreglo nuevo que contenga las fechas faltantes de cada video con 0 vistas
                        const dataWebinars = webinarsWithDates.map(video => {
                            const arrDates = video.dates.map(date => date.register_date)
                            // Filtrar los valores de arrMonthDays que no están en arrDates
                            const diferencia = arrMonthDays.filter(valor => !arrDates.includes(valor));
                            const arrDatesMissings = diferencia.map(date => {
                                return {
                                    count: 0,
                                    idWebinar: video.idWebinar,
                                    register_date: date,
                                }
                            })
                            // Unir los dos arrays 
                            const arrayUnido = video.dates.concat(arrDatesMissings);
                            // Ordenar el array por la propiedad 'fecha' (de más antigua a más reciente)
                            const arrayOrdenado = arrayUnido.sort((a, b) => new Date(a.register_date) - new Date(b.register_date));
                            // Regresamos el valor del nuevo arreglo
                            return {
                                description: video.description,
                                idWebinar: video.idWebinar,
                                dates: arrayOrdenado
                            }

                        })

                        // console.log('dataWebinars: ',dataWebinars);

                        // En este arreglo acomodamos de acuerdo a los campos que necesitamos en series del gráfico
                        const info = dataWebinars.map(video => (
                            {
                            name: video.description,
                            data: video.dates.map(elm => elm.count)
                            })
                        );

                        //Guardamos la información para el grafico 
                        const maxView = Math.max(...info.flatMap(obj => obj.data));

                        setDailyMonthViews(oldValues => ({
                            series: info,
                            options: {
                                ...oldValues.options,
                                xaxis: {
                                    ...oldValues.options.xaxis,
                                    categories: arrMonthDays
                                },
                            }
                        }));
                        setDailyMonthShow(true);


                        setDailyStackedMonthViews(oldValues => ({
                            series: info,
                            options: {
                                ...oldValues.options,
                                xaxis: {
                                    ...oldValues.options.xaxis,
                                    categories: arrMonthDays,
                                },
                                
                            }
                        }))
                        setDailyStackedMonthShow(true);
                    }
                }
            });

            /* getEnterpriseWebinarPercentage(enterpriseSelected, month, year).then(response => {
                if (response.validation) {
                    // console.log(response.data);
                    if (response.data.webinarPercentage.length > 0) {
                        const processData = processChartData(response.data.webinarPercentage);
                        // console.log('processData: ',processData);
                        // Combinar todos los arrays de data y encontrar el valor máximo
                        const maximo = Math.max(...processData.series.flatMap(obj => obj.data)) + 1;
                        if (Object.keys(processData).length > 0) {
                            setVideosPercent(oldValues => ({
                                series: processData.series,
                                options: {
                                    ...oldValues.options,
                                    xaxis: {
                                        ...oldValues.options.xaxis,
                                        categories: processData.titles
                                    },
                                    yaxis: {
                                        ...oldValues.options.yaxis,
                                        max: maximo, // Valor máximo del eje Y, ajusta según tus datos  
                                    },
                                }
                            }));
                            setProgressShow(true);
                        }
                    }
                }
            }); */

            getEnterpriseWebinarPercentage(enterpriseSelected, month, year).then((response) => {
                if (response.validation) {
                    if (response.data.webinarPercentage.length > 0) {
                        const processChartData = (data) => {
                            // Categorías predefinidas
                            const predefinedProgressCategories = [0, 25, 50, 75, 100];
            
                            // Agrupar por idWebinar
                            const groupedData = {};
                            data.forEach((item) => {
                                if (!groupedData[item.idWebinar]) {
                                    groupedData[item.idWebinar] = {
                                        title: item.title, // Título del video
                                        progress: {}, // Objeto para almacenar progreso
                                    };
                                }
                                groupedData[item.idWebinar].progress[item.time] = 
                                    (groupedData[item.idWebinar].progress[item.time] || 0) + item.views;
                            });
            
                            // Completar categorías faltantes con 0
                            const titles = Object.values(groupedData).map((entry) => entry.title); // Títulos de los webinars
                            const series = predefinedProgressCategories.map((time) => ({
                                name: `${time}%`, // Nombre de la serie
                                data: Object.values(groupedData).map(
                                    (entry) => entry.progress[time] || 0 // Usar 0 si falta la categoría
                                ),
                            }));
            
                            return { titles, series };
                        };
            
                        const processData = processChartData(response.data.webinarPercentage);
            
                        // Definir colores para los niveles de progreso
                        const colorsMap = {
                            0: "#9cb537",
                            25: "#af2f72",
                            50: "#2188b4",
                            75: "#dfa443",
                            100: "#7d9645",
                        };
            
                        const predefinedColors = [0, 25, 50, 75, 100].map(
                            (time) => colorsMap[time]
                        );
            
                        // Encontrar el máximo en los datos
                        const maximo = Math.max(...processData.series.flatMap((obj) => obj.data)) + 1;
            
                        setVideosPercent((oldValues) => ({
                            series: processData.series,
                            options: {
                                ...oldValues.options,
                                xaxis: {
                                    ...oldValues.options.xaxis,
                                    categories: processData.titles,
                                },
                                yaxis: {
                                    ...oldValues.options.yaxis,
                                    max: maximo, // Ajustar el máximo del eje Y
                                },
                                colors: predefinedColors, // Asignar colores predefinidos
                            },
                        }));
                        setProgressShow(true);
                    }
                }
            });
            setShowData(true);
        }else{
            toastr.info('Por favor, seleccione una empresa.', '¡Ooops!')

        }
        
    }
    
  return (
    <div className="col-11 mx-auto">
        <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
            <div className='card-body'>
                <div className='col-sm-11 col-lg-12 col-xxl-12 mx-auto generic__show-container top container-1'>
                    <div className='row'>
                        <div className="col-12 p-0 d-flex justify-content-center mb-2">
                            <div className="col-1 text-center">
                                <span className="fw-bold w-35">fecha: </span>
                            </div>
                            <div className="col-2 ">
                                <Datepicker
                                    dateFormat="MMMM yyyy" // Formato: mes en letras y año
                                    className="form-control selectBorder"
                                    selected={selectedDate} // Fecha seleccionada
                                    onChange={(date) => handleGetEnterprises(date)} // Cambiar la fecha
                                    showMonthYearPicker // Mostrar solo selector de mes y año
                                    minDate={new Date(2024, 9, 1)} // Fecha mínima: Octubre 2024
                                    maxDate={new Date()} // Fecha máxima: la fecha actual
                                    locale="es"
                                />
                                
                            </div>
                            {
                                enterprises.length > 0 && (
                                    <>
                                        <div className="col-1 text-center">
                                            <span className="fw-bold">Empresa:</span>
                                        </div>
                                        <div className="col-6">
                                            <select
                                                className="form-select bg-white selectBorder"
                                                id="enterprises"
                                                name="enterprises"
                                                value={enterpriseSelected}
                                                onChange={(e) => {
                                                    setEnterpriseSelected(e.target.value);
                                                    setShowData(false);
                                                }}
                                            >
                                                <option key={"enterprise-" + 0} value={0}>Seleccione...</option>
                                                {enterprises.map(data => (
                                                    <option key={"enterprise-" + data.idEnterprise} value={data.idEnterprise}>{data.comercialName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                )
                            }
                            {
                                enterprises.length > 0 && (
                                    <div className="col-1 d-flex justify-content-end ">
                                        <button
                                            type="button"
                                            onClick={(e) => handleGetData(e) }
                                            className="btn btn-sm btn-primary btn-pink-guay"
                                        >Consultar</button>
                                    </div>
                                )
                            }
                            
                                                        

                        </div>
                        {
                            !showData ? (
                                
                                <div className='col-12 text-center py-5'>
                                    {/* <span className='graphicTitle'>Progreso de Reproducciones de Webinars</span> */}
                                    <span>{enterprises.length > 0 ? 'Consulta información' : 'En el mes seleccionado no hay empresas con informacón. Puedes consultar de otro mes.'}</span>
                                </div>
                                ) 
                            :
                                (
                                <>
                                    {/* PORCENTAJES DE REPRODUCCIONES */}
                                    <div className='col-12 mx-auto mb-3'>
                                        <div className='card shadow border-1 rounded-25-px generic__show-main-container container-1'>
                                            <div className='card-body'>
                                                {!progressShow ? (
                                                        <div className='col-12 text-center py-5'>
                                                            <span className='graphicTitle'>% de Progreso en las Rreproducciones de los Webinars</span>
                                                            <h1>No hay información</h1>
                                                        </div>
                                                        
                                                    ) : Object.keys(videosPercent).length > 0 &&  Object.keys(videosPercent.series).length > 0 && (
                                                            <Chart
                                                                options={videosPercent.options}
                                                                series={videosPercent.series}
                                                                type="bar"
                                                                height={350}
                                                            />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                        
                                    {/* <div className='col-12 mx-auto mb-3'>
                                        <div className='card shadow border-1 rounded-25-px generic__show-main-container container-1'>
                                            <div className='card-body'>
                                                {!dailyMonthShow ? (
                                                        <div className='col-12 text-center py-5'>
                                                            <span className='graphicTitle'>Reproducciones Diarias de Webinars</span>
                                                            <h1>No hay información</h1>
                                                        </div>
                                                        
                                                    ) : Object.keys(dailyMonthViews).length > 0 &&  Object.keys(dailyMonthViews.series).length > 0 && (
                                                        <Chart
                                                            options={dailyMonthViews.options}
                                                            series={dailyMonthViews.series}
                                                            type="bar"
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>  */}

                                    <div className='col-12 mx-auto mb-3'>
                                        <div className='card shadow border-1 rounded-25-px generic__show-main-container container-1'>
                                            <div className='card-body'>
                                                {!dailyStackedMonthShow ? (
                                                        <div className='col-12 text-center py-5'>
                                                            <span className='graphicTitle'>Reproducciones Diarias de Webinars</span>
                                                            <h1>No hay información</h1>
                                                        </div>
                                                        
                                                    ) : Object.keys(dailyStackedMonthViews).length > 0 &&  Object.keys(dailyMonthViews.series).length > 0 && (
                                                        <Chart
                                                            options={dailyStackedMonthViews.options}
                                                            series={dailyStackedMonthViews.series}
                                                            type="bar"
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div> 
                                </>
                                )
                        }
                        


                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EnterpriseInfo
