import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import { Notifications } from 'react-push-notification'
import Header from '../components/genericComponents/Header';
import { Footer } from '../components/genericComponents/Footer';
import Menu from '../components/genericComponents/Menu';
import { CampaignScreen } from '../components/campaign/createCampaign/CampaignScreen';
import SponsorRegister from '../components/sponsor/SponsorRegister';
import Organizations from '../components/organization/Organizations';
import Sponsors from '../components/sponsor/Sponsors';
import StatusController from '../components/shop/commercial/StatusStructure/StatusController';
import BenefitsScreen from '../components/benefits/BenefitsScreen';
import RscController from '../components/guayResources/RscController';
import EmailConfig  from '../components/admin/EmailConfig';
import { AddUsers } from '../components/operations/AddUsers';
import GroupsEnterprises from '../components/enterpriseAndGroup/GroupsEnterprises';
import GroupForm from '../components/enterpriseAndGroup/group/GroupForm';
import PromotionsIndex from '../components/promotions/PromotionsIndex';
import PlansBenefist from '../components/plans/benefits/Controller';
import PlansPromotions from '../components/plans/promotions/Controller';
import Webinars from '../components/operations/Webinars/Webinars';

export const AppOutlet = ({ isAuthenticated }) => {

    return isAuthenticated ? (
        <div className="row">
            <Notifications />
            <Header />
            <div className="col-sx-12 col-sm-12 col-md-2 col-lg-2">

                <div className="row sticky-guay">
                    <Menu />
                </div>
            </div>
            <div className="col-sx-12 col-sm-12 col-md-10 col-lg-10 mb-5">
                <div id='top-page' />
                <div className="row">
                    <Routes>
                        <Route path="campaign" element={<CampaignScreen />} />
                        <Route path="sponsor-register" element={<SponsorRegister />} />
                        <Route path="organizations" element={<Organizations />} />
                        <Route path="sponsors" element={<Sponsors />} />
                        <Route path="status-request" element={<StatusController />} />
                        <Route path="benefits" element={<BenefitsScreen />} />
                        <Route path="resources" element={<RscController />} />
                        <Route path="email-config" element={<EmailConfig />} />
                        <Route path="add-users" element={<AddUsers />} />
                        <Route path="groups-enterprises"element={<GroupsEnterprises/>} />
                        <Route path="group-register"element={<GroupForm/>} />
                        <Route path="promotions"element={<PromotionsIndex/>} />
                        <Route path="plans-benefits"element={<PlansBenefist/>} />
                        <Route path="plans-promotions"element={<PlansPromotions/>} />
                        <Route path="webinar-analytics"element={<Webinars/>} />

                        <Route path="*" element={<Navigate replace to='groups-enterprises' />} />
                    </Routes>
                </div>
            </div>
            <Footer />
        </div>
    ) : (
        <Navigate replace to="admin-access/login" />
    )
}
