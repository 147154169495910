import axios from 'axios';
import * as toastr from 'toastr';

export const getWebinarsCountViewsLast15Days = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getWebinarsCountViewsLast15Days`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    toastr.info('No hay webinars activos.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getWebinarDateInfoViews = async (enterprisesInfo) => {    
    const {idWebinar, month, year} = enterprisesInfo;
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getWebinarDateInfoViews?idWebinar=${idWebinar}&month=${month}&year=${year}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    toastr.info('El webinar no fue encontrado.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    toastr.info('El webinar no tiene vistas.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getTop5ViewedWebinars = async (month, year) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getTop5ViewedWebinars?month=${month}&year=${year}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    //toastr.info('No hay webinars activas.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    toastr.info('No se encontraron reproducciones.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getTop5EnterpriseViews = async (month, year) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getTop5EnterpriseViews?month=${month}&year=${year}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    //toastr.info('No hay webinars activas.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1001:
                    toastr.info('No se encontraron reproducciones.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getEnterpriseWithWebinarViews = async (month, year) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getEnterpriseWithWebinarViews?month=${month}&year=${year}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    //toastr.info('No hay webinars activas.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getWebinarsCountViewsLastMonth  = async (idEnterprise,month,year) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getWebinarsCountViewsLastMonth?idEnterprise=${idEnterprise}&month=${month}&year=${year}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    toastr.info('No hay webinars activos.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getEnterpriseWebinarPercentage  = async (idEnterprise,month,year) => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getEnterpriseWebinarPercentage?month=${month}&year=${year}&idEnterprise=${idEnterprise}`,
            headers: {
                Authorization: global.tokenAuth,
            },
        })
        let obj = {
            data: {},
            validation: true,
        }
        if (result.data) {
            switch (result.data.code) {
                case 1:
                    obj.data = result.data
                    break;
                case 200:
                    obj.data = result.data
                    break;
                case 99:
                    toastr.info('Problemas con la conexión a internet o con el servidor. Intenta nuevamente más tarde.', '¡Ooops!')
                    obj.validation = false
                    break;
                case 1000:
                    toastr.info('No hay información de los webinars.', '¡Ooops!')
                    obj.validation = false
                    break;
                default:
                    toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result.data.code}`, '¡Ooops!')
                    obj.validation = false
                    break;
            }
        } else {
            toastr.info(`Error desconocido: Favor de contactar al equipo de soporte. Code: ${result}`, '¡Ooops!')
            obj.validation = false
        }
        return obj
    } catch (error) {
        console.log(error)
    }
}

export const getWebinarExcelDataByYear = async (year) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${global.base_url_admin}webinar/getWebinarExcelDataByYear?year=${year}`,
            headers: {
                Authorization: global.tokenAuth,
            },
            responseType: 'blob', // Esto permite manejar archivos binarios como respuesta.
        });

        // Crear un objeto URL para el archivo descargado.
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace de descarga y activarlo.
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `webinars_${year}.xlsx`); // Cambiar el nombre si es necesario.
        document.body.appendChild(link);
        link.click();

        // Limpiar el enlace y el objeto URL.
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        return {
            validation: true,
            message: 'Archivo descargado exitosamente.',
        };
    } catch (error) {
        console.error(error);
        toastr.info(
            'Ocurrió un error al intentar descargar el archivo. Por favor, intenta nuevamente.',
            '¡Ooops!'
        );
        return {
            validation: false,
            message: 'Error al descargar el archivo.',
        };
    }
};