import React, { useState } from 'react'
import MenuWebbinars from './MenuWebbinars';
import "react-datepicker/dist/react-datepicker.css";
import GeneralInfo from './GeneralInfo';
import EnterpriseInfo from './EnterpriseInfo';


const Webinars = () => {
    const [section, setSection] = useState(0);
 
  return (
    <div className="col-12 my-5">
        <div className="row">
            <div className="col-11 mx-auto mb-3">
                <h3>Analíticos de Webinars</h3>
            </div>
            <div className="col-11 mx-auto sticky-panel">
                <MenuWebbinars section={section} setSection={setSection} />
            </div>
            
            {
                section === 0 ? (  
                    <GeneralInfo />

                ):(
                    <EnterpriseInfo/>
                )
            }
            
            
        </div>
    </div>
  )
}

export default Webinars
