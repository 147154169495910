import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const Menu = () => {
    const [showIcon1, setshowIcon1] = useState(false)
    const [showIcon2, setshowIcon2] = useState(false)
    useEffect(() => {
        switch (window.location.pathname) {
            case '/groups-enterprises':
                $(`#1`).addClass('menu__menu-option-selected')
                break;
            case '/status-request':
                $(`#2`).addClass('menu__menu-option-selected')
                break;
            case '/benefits':
                $(`#3`).addClass('menu__menu-option-selected')
                break;
            case '/sponsors':
                $(`#4`).addClass('menu__menu-option-selected')
                break;
            case '/organizations':
                $(`#7`).addClass('menu__menu-option-selected')
                break;
            case '/sponsor-register':
                $(`#8`).addClass('menu__menu-option-selected')
                break;
            case '/resources':
                $(`#9`).addClass('menu__menu-option-selected')
                break;
            case '/email-config':
                $(`#10`).addClass('menu__menu-option-selected')
                break;
            case '/add-users':
                $(`#11`).addClass('menu__menu-option-selected')
                break;
            case '/promotions':
                $(`#12`).addClass('menu__menu-option-selected')
                $(`#collapseExample`).addClass('show')
                break;
            case '/plans-benefits':
                $(`#13`).addClass('menu__menu-option-selected')
                $(`#collapseExample`).addClass('show')
                break;
            case '/plans-promotions':
                $(`#14`).addClass('menu__menu-option-selected')
                $(`#collapseExample`).addClass('show')
                break;
            case '/webinar-analytics':
                $(`#15`).addClass('menu__menu-option-selected')
                $(`#collapseExample`).addClass('show')
                break;
            default:
                // $(`#0`).addClass('menu__menu-option-selected')
                $(`#1`).addClass('menu__menu-option-selected')
                break;
        }
    }, []);

    const handleSelection = id => {
        if (id === 1 && window.location.pathname === '/groups-enterprises') {
            window.location.reload()
        }
        for (let index = 0; index < 16; index++) {
            if (index === id) {
                $(`#${id}`).addClass('menu__menu-option-selected')
            } else {
                $(`#${index}`).removeClass('menu__menu-option-selected')
            }
        }
    }

    /* const handleChangeIcon = id => {
        if($(`#${id}-img`).hasClass('show-false')){
            $(`#${id}-img`).removeClass('show-false')
            $(`#${id}-img`).attr("src","../assets/img/icons/Icono_agregar_menos_activo.png")
        }else{
            $(`#${id}-img`).addClass('show-false')
            $(`#${id}-img`).attr("src","../assets/img/icons/Icono_agregar_activo.png")
        }
        
    } */

    return (
        <div className="col-12 my-5">
            <div className="row">
                <div className="col-11 mx-auto shadow bg-green-guay pt-4" style={{ borderRadius: '25px' }}>
                    <div className="row d-flex bg-white justify-content-end pb-4" style={{ borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px' }}>
                        <Link to="groups-enterprises" id='0' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(0)}>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-10 p-0'><span className="show-1 fw-semibold font-family-quicksand">Inicio</span></div>
                            </div>
                        </Link>
                        <Link to="groups-enterprises" id='1' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(1)}>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-10 p-0'><span className="show-2 fw-semibold font-family-quicksand">Grupo - Organización</span></div>
                            </div>
                        </Link>
                        <div
                            className="col-11 menu__menu-option text-decoration-none show-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            //onClick={() => handleChangeIcon('collapseExample')}
                            onClick={() => setshowIcon1(!showIcon1)}
                        >
                            <div className='row'>
                                <div className='col-2'>
                                    <img
                                        //src="../assets/img/icons/Icono_agregar_activo.png" 
                                        src={showIcon1 ? '../assets/img/icons/icono_agregar_menos_activo.png' : '../assets/img/icons/Icono_agregar_activo.png'}
                                        id='collapseExample-img'
                                        className="icon-size-10x10 pointer show-false" alt="img-desplegar-menu"
                                    />
                                </div>
                                <div className='col-10 p-0'><span className="show-3 fw-semibold font-family-quicksand">Servicios</span></div>
                            </div>
                        </div>
                        <div className="collapse" id="collapseExample">
                            <div className='row d-flex justify-content-end'>
                                <Link to="status-request" id='2' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(2)}>
                                    <span className="show-1 fw-semibold font-family-quicksand">Ventas</span>
                                </Link>
                                <Link to="benefits" id='3' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(3)}>
                                    <span className="show-2 fw-semibold font-family-quicksand">Beneficios</span>
                                </Link>
                                <Link to="promotions" id='12' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(12)}>
                                    <span className="show-3 fw-semibold font-family-quicksand">Promociones</span>
                                </Link>
                                <Link to="plans-benefits" id='13' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(13)}>
                                    <span className="show-4 fw-semibold font-family-quicksand">Planes - Beneficios</span>
                                </Link>
                                <Link to="plans-promotions" id='14' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(14)}>
                                    <span className="show-5 fw-semibold font-family-quicksand">Planes - Promociones</span>
                                </Link>
                            </div>
                        </div>
                        <Link to="sponsors" id='4' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(4)}>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-10 p-0'><span className="show-4 fw-semibold font-family-quicksand">Patrocinadores</span></div>
                            </div>
                        </Link>
                        {/* <Link to="groups-enterprises" id='0' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(0)}>
                            <div className='row'>
                                <div className='col-2'></div>
                                <div className='col-10 p-0'><span className="show-5 fw-semibold font-family-quicksand">Usuarios</span></div>
                            </div>
                        </Link> */}
                        <div
                            className="col-11 menu__menu-option text-decoration-none show-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOthers"
                            aria-expanded="false"
                            aria-controls="collapseOthers"
                            //onClick={() => handleChangeIcon('collapseOthers')}
                            onClick={() => setshowIcon2(!showIcon2)}
                        >
                            <div className='row'>
                                <div className='col-2'>
                                    <img
                                        //src="../assets/img/icons/Icono_agregar_activo.png" 
                                        src={showIcon2 ? '../assets/img/icons/icono_agregar_menos_activo.png' : '../assets/img/icons/Icono_agregar_activo.png'}
                                        id='collapseOthers-img'
                                        className="icon-size-10x10 pointer show-false" alt="img-desplegar-menu"
                                    />
                                </div>
                                <div className='col-10 p-0'><span className="show-3 fw-semibold font-family-quicksand">Otros</span></div>
                            </div>
                        </div>
                        <div className="collapse" id="collapseOthers">
                            <div className='row d-flex justify-content-end'>
                                <Link to="campaign" id='0' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(0)}>
                                    <span className="show-1 fw-semibold font-family-quicksand">Campaña</span>
                                </Link>
                                <Link to="organizations" id='7' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(7)}>
                                    <span className="show-2 fw-semibold font-family-quicksand">Organizaciones</span>
                                </Link>
                                <Link to="sponsor-register" id='8' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(8)}>
                                    <span className="show-3 fw-semibold font-family-quicksand">Registro</span>
                                </Link>
                                <Link to="resources" id='9' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(9)}>
                                    <span className="show-4 fw-semibold font-family-quicksand">Recursos</span>
                                </Link>
                                <Link to="email-config" id='10' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(10)}>
                                    <span className="show-5 fw-semibold font-family-quicksand">Configuración de Correo</span>
                                </Link>
                                <Link to="add-users" id='11' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(11)}>
                                    <span className="show-6 fw-semibold font-family-quicksand"> Agregar usuarios a ejercicios en curso</span>
                                </Link>
                                <Link to="webinar-analytics" id='15' className="col-11 menu__menu-option text-decoration-none" onClick={() => handleSelection(15)}>
                                    <span className="show-6 fw-semibold font-family-quicksand"> Analíticos de webinars</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Menu;
