import $ from 'jquery'

/**
 * 
 * @param {*} target 
 * @param {*} elementId 
 * @param {*} elementClass 
 * @param {*} elementTag 
 * @returns 
 */
export const sortArray = ({ target }, elementId, elementClass, elementTag) => {
    let filter, ul, li, a, i, txtValue;
    filter = target.value.toLowerCase();
    ul = document.getElementById(elementId);
    li = ul.getElementsByClassName(elementClass);
    let records = 0;
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName(elementTag)[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toLowerCase().indexOf(filter) > -1) {
            li[i].style.display = '';
            records = records = 1;
        } else {
            li[i].style.display = 'none';
        }
    }
    if (records === 0) {
        return true
    } else {
        return false
    }
}

export const handleDropdown = id => {
    if ($(`#divDetalle-${id}`).hasClass('d-none')) {
        $(`#divDetalle-${id}`).removeClass('d-none')
        $(`#divDetalle-${id}`).addClass('detail-show')
        $(`#imgDetail-${id}`).attr('src', '../assets/img/icons/icono_agregar_menos_activo.png')
    } else {
        $(`#divDetalle-${id}`).removeClass('detail-show')
        $(`#divDetalle-${id}`).addClass('d-none')
        $(`#imgDetail-${id}`).attr('src', '../assets/img/icons/Icono_agregar_activo.png')
    }
}

export const colorsArray = ["#9cb537", "#af2f72", "#2188b4", "#dfa443",
    "#7d9645", "#be5b70", "#4e81ad", "#d69356",
    "#3f4c40", "#9a5587", "#6979a4", "#c7a73d",
    "#29514e", "#8d6391", "#7d6f9b", "#15253c"]

export const last15Days = () =>{
    const fechas = [];
    const hoy = new Date();

    for (let i = 14; i >= 0; i--) {
    // Clonamos la fecha de hoy y restamos los días
    const fecha = new Date(hoy);
    fecha.setDate(hoy.getDate() - i);
    
    // Formateamos la fecha a "año-mes-día" (ejemplo: 2023-11-15)
    const fechaFormateada = fecha.toISOString().split('T')[0];  // "2023-11-15"

    // Agregamos la fecha formateada al array
    fechas.push(fechaFormateada);
    }
    return fechas;
}

export const lastDays = () =>{
    const fechas = [];
    const hoy = new Date();

    for (let i = 14; i >= 0; i--) {
    // Clonamos la fecha de hoy y restamos los días
    const fecha = new Date(hoy);
    fecha.setDate(hoy.getDate() - i);
    
    // Formateamos la fecha a "año-mes-día" (ejemplo: 2023-11-15)
    const fechaFormateada = fecha.toISOString().split('T')[0];  // "2023-11-15"

    // Agregamos la fecha formateada al array
    fechas.push(fechaFormateada);
    }
    return fechas;
}

export const getMonthDays = (year,month) => {
    // Determinar el último día del mes
    const lastDay = new Date(year, month, 0).getDate();

    // Crear un array con las fechas en formato "YYYY-MM-DD"
    const dates = Array.from({ length: lastDay }, (_, i) => {
        const day = i + 1;
        // Formatear la fecha como "YYYY-MM-DD"
        return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    });

  return dates;
}

export const convertDateToString = (date) => {
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}



